@media (max-width: 1920px) {
    html {
        font-size: calc(100vw / 1920);
    }
}

@media (max-width: 991px) {
    html {
        font-size: calc(100vw / 768);
    }

    .container {
        max-width: 741rem;
    }

    .header {
        padding-top: 20rem;
        padding-bottom: 67rem;

        .container {
            flex-wrap: wrap;
        }

        .search {
            width: 100%;
            order: 1;
            padding-top: 0;
            margin-top: 60rem;

            input {
                width: 100%;
                height: 38rem;
                font-size: 17rem;
            }

            span {
                font-size: 17rem;
            }
        }

        &-left {
            margin-right: 0;

            .logo {
                width: 130rem;
            }

            .menu {
                margin-top: 42rem;

            }

        }

        &-right {
            padding-top: 28rem;

            ul {
                width: 218rem;

                img {
                    width: 34rem;
                }
            }
        }

        &__phone {
            margin-bottom: 40rem;

            span {
                font-size: 20rem;
                line-height: 24rem;
            }
        }
    }

    .thankyou {
        padding-top: 0rem;
        padding-bottom: 70rem;

        .hero-left {
            min-width: 0rem;
            max-width: 328rem;
        }

        h1 {
            font-size: 40rem;
            line-height: 48rem;
            margin-bottom: 28rem;
        }

        p {
            font-size: 16rem;
            line-height: 19rem;
            margin-bottom: 75rem;
        }

        .hero-right img {

            width: 384rem;

        }
    }

    .hero {
        padding-bottom: 70rem;

        .container {}

        &-left {
            padding-top: 0;
            max-width: 328rem;
            min-width: 0;
            margin-right: 0;

            h1 {
                font-size: 40rem;
                line-height: 48rem;
                margin-bottom: 28rem;
            }

            p {
                font-size: 16rem;
                line-height: 19rem;
                margin-bottom: 75rem;
            }

        }

        &-right {
            margin-left: auto;

            img {
                width: 384rem;
            }
        }

    }

    .video {
        padding-top: 72rem;
        padding-bottom: 150rem;
        background-size: 100% auto;

        h2 {
            margin-bottom: 78rem;
        }
    }

    .product {
        &-top {
            margin-bottom: 50rem;
        }

        &-switch {
            &-wrap {
                width: 87rem;
                height: 42rem;
                border-radius: 30rem;
            }

            input:checked+.product-switch-wrap {
                .product-switch-icon {
                    transform: translateX(45rem);
                }
            }

            &-icon {
                width: 38rem;
                height: 38rem;
                top: 2rem;
                left: 2rem;
            }
        }

        .container {
            border-radius: 16rem;
            padding: 50rem 30rem 50rem;
        }

        &__title {
            font-size: 32rem;
            line-height: 39rem;
        }

        &-slide {
            display: grid;
            grid-template-columns: 100rem 524rem;
            grid-gap: 60rem;
            margin-bottom: 37rem;
        }

        &-thumb {
            width: 100%;
            height: 480rem;
        }

        &-gallery {
            width: 100%;
            height: 480rem;
            position: relative;
            background: #FFFFFF;
            border-radius: 16rem;

            &__sale {
                position: absolute;
                top: 0rem;
                left: 0rem;
                width: 63rem;
                height: 38rem;
            }

            &__img {
                max-width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 71rem;
            }
        }

        &-mobile {
            display: block;

            &-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 38rem;
            }

            .product-right__price {
                margin-bottom: 0;
            }

            .product-right__info {
                font-size: 12rem;
                line-height: 15rem;
                margin-bottom: 7rem;
            }

            .product-right__article {
                font-size: 16rem;
                line-height: 144.02%;
                margin-bottom: 0;
            }

            &-action {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 50rem;

                .btn-red {
                    width: 327rem;
                    margin-bottom: 0;
                }

                .btn {
                    width: 327rem;
                    margin-bottom: 0;
                }
            }

        }

        &-left {
            max-width: 100%;
            margin-bottom: 0;
        }

        &-desc {
            &-nav {
                margin-bottom: 50rem;

                ul {
                    a {
                        padding: 0;
                    }

                    li.active {
                        a {
                            padding: 0 40rem;
                        }
                    }

                    li:last-child {
                        a {
                            padding-right: 40rem;
                        }
                    }

                    li:first-child {
                        a {
                            padding-left: 40rem;
                        }
                    }
                }
            }

            &-tech {
                margin-bottom: 30rem;

                &-item {
                    margin-bottom: 20rem;
                    height: 166rem;
                    border: 2px solid rgba(47, 128, 237, 0.2);
                    border-radius: 16rem;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    padding: 0 30rem;

                    &__icon {
                        margin-right: 0rem;
                        margin-bottom: 20rem;
                    }
                }
            }
        }

        &-question {
            &-item {
                border: 2px solid rgba(47, 128, 237, 0.0);

                &.active {
                    border: 2px solid rgba(47, 128, 237, 0.2);
                }
            }
        }

        &-right {
            max-width: 100%;
            margin-top: 50rem;

            .product-right__info {
                display: none;
            }

            .product-right__article {
                display: none;
            }

            .product-right__price {
                display: none;
            }

            .btn-red {
                display: none;
            }

            .btn {
                display: none;
            }
        }

        &-delivery {
            margin-bottom: 40rem;

            &-item {
                margin-bottom: 0rem;
            }

            &-wrap {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 20rem;
            }
        }

        &-yamaha {
            margin-bottom: 50rem;
        }

        &-bottom {
            border: 2rem solid #FFC500;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &-left {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10rem;

                a {
                    margin: 0 22rem;

                    img {
                        width: 68rem;
                    }
                }
            }

            &-right {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                strong {
                    margin-bottom: 16rem;
                }
            }
        }
    }

    .map {
        .container {
            padding-top: 70rem;
            padding-bottom: 60rem;

            &:after {
                display: none;
            }
        }

        h2 {
            margin: 0 auto 60rem;
            max-width: 416rem;
        }

        &-wrapper {
            flex-direction: column-reverse;
        }

        &-info {
            width: 100%;
            min-width: 100%;
            padding-top: 0;
            margin-bottom: 50rem;

            &__title {
                font-size: 28rem;
                line-height: 34rem;
                margin-bottom: 60rem;
            }

            p {
                max-width: 100%;
                font-size: 26rem;
                line-height: 31rem;
                font-weight: 500;
            }
        }

        &-block {
            width: 762rem;
            min-width: 762rem;
            height: 589rem;
        }

        &-item {
            &--1 {
                width: 618rem;
                height: 384rem;
                top: 0;
                left: 46rem;

                .map-profile {
                    &--1 {
                        width: 114rem;
                        top: 183rem;
                        left: 212rem;

                        .map-profile__name {

                            font-size: 11rem;
                            line-height: 13rem;
                            top: -13rem;
                            left: -8rem;
                        }
                    }

                    &--2 {
                        width: 131rem;
                        top: 86rem;
                        left: 355rem;

                        .map-profile__name {
                            font-weight: 500;
                            font-size: 12.6316rem;
                            line-height: 15rem;
                            top: -13rem;
                            left: 17rem;
                        }
                    }
                }

                .map-item__flag {
                    top: 236rem;
                    left: 90rem;
                    width: 40rem;
                }

            }

            &--2 {
                width: 388rem;
                height: 275rem;
                bottom: 0;
                left: 0;

                .map-item__flag {
                    top: 145rem;
                    left: 160rem;
                    width: 40rem;
                }

                .map-profile {
                    &--1 {
                        width: 104rem;
                        top: 98rem;
                        left: 19rem;

                        .map-profile__name {
                            font-size: 9.97968rem;
                            line-height: 12rem;
                            top: -11rem;
                            left: 13rem;
                        }
                    }

                    &--2 {
                        width: 92rem;
                        top: 33rem;
                        left: 152rem;

                        .map-profile__name {
                            font-weight: 500;
                            font-size: 8.90301rem;
                            line-height: 11rem;
                            top: -7rem;
                            left: 12rem;
                        }
                    }

                    &--3 {
                        width: 129rem;
                        top: 64rem;
                        left: 261rem;

                        .map-profile__name {
                            font-weight: 500;
                            font-size: 12.4794rem;
                            line-height: 15rem;
                            top: -13rem;
                            left: 17rem;
                        }
                    }
                }
            }

            &--3 {
                width: 401rem;
                height: 222rem;
                bottom: 66rem;
                right: 0rem;

                .map-item__flag {
                    top: 90rem;
                    left: 50rem;
                    width: 40rem;
                }

                .map-profile {
                    &--1 {
                        width: 104rem;
                        top: 68rem;
                        left: 139rem;

                        .map-profile__name {
                            font-weight: 500;
                            font-size: 10.0444rem;
                            line-height: 12rem;
                            top: -11rem;
                            left: 13rem;
                        }
                    }

                    &--2 {
                        width: 112rem;
                        top: 7rem;
                        left: 242rem;

                        .map-profile__name {
                            font-weight: 500;
                            font-size: 12.6316rem;
                            line-height: 15rem;
                            top: -12rem;
                            left: 17rem;
                        }
                    }
                }
            }
        }
    }

    .we {
        padding-top: 54rem;
        padding-bottom: 70rem;

        h2 {
            margin-bottom: 100rem;
        }

        &-wrapper {
            grid-template-columns: 1fr 1fr;
            max-width: 575rem;
            grid-column-gap: 65rem;
            grid-row-gap: 40rem;
            margin: 0 auto 114rem;
        }

        &-item {
            &__icon {
                width: 47rem;
                margin-bottom: 20rem;
            }

            h3 {
                font-size: 28rem;
                line-height: 34rem;
                margin-bottom: 18rem;
            }

            p {
                font-size: 16rem;
                line-height: 23rem;
            }
        }

        &-slider {
            max-width: 573rem;
            margin: 0 auto;

            .swiper-container {
                overflow: visible;
            }

            &-item {
                padding: 15rem 19rem;

                &__img {
                    margin-bottom: 16rem;

                    img {
                        height: 196rem;
                    }

                }

                h3 {
                    font-size: 17rem;
                    line-height: 21rem;
                    margin-bottom: 62rem;
                }

                &__number {
                    font-size: 13rem;
                    line-height: 16rem;
                }
            }

            &-nav {
                margin-top: 35rem;
                justify-content: flex-start;
            }
        }
    }

    .how {
        padding-top: 70rem;
        padding-bottom: 70rem;
        &-dots {
            display: none;
        }
        &:after {
            display: none;
        }

        .btn--mobile {
            display: flex;
            margin: 0 auto;
        }

        &-left {
            max-width: 100%;
            width: 100%;

            .btn {
                display: none;
            }
        }

        &-right {
            width: 100%;
            margin-bottom: 70rem;
        }

        .container {
            flex-direction: column;
            align-items: flex-start;
        }

        &__title {
            font-size: 64rem;
            line-height: 77rem;
            text-align: center;
            margin: 0 auto 70rem;
        }

        &-items {
            margin-right: 0;
            max-width: 100%;
        }

        &-item {
            padding: 33rem 34rem;
            align-items: center;

            &__number {
                width: 29rem;
                height: 29rem;
                margin-right: 20rem;
                background: #2F80ED;
                color: #fff;
            }

            p {
                font-size: 20px;
                line-height: 24px;
                color: #fff;
            }
        }

        &-dots {
            margin-left: auto;

            span {
                width: 6rem;
                height: 6rem;
                margin-bottom: 28rem;
            }
        }
    }

    .reviews {
        padding-top: 70rem;
        padding-bottom: 70rem;

        h2 {
            margin-bottom: 0;
            font-size: 32rem;
            line-height: 39rem;
        }

        &-wrapper {
            flex-direction: column;
        }

        &-nav {
            position: static;
            transform: translate(0);
            max-width: 270rem;
            margin: 0 auto 81rem;
        }

        &-info {
            order: -1;

            &-item {
                padding-top: 123rem;
                padding-bottom: 105rem;
                padding-right: 35rem;
                padding-left: 108rem;

                &__top {
                    font-size: 18rem;
                    line-height: 22rem;
                    margin-bottom: 22rem;
                }

                p {
                    font-size: 24rem;
                    line-height: 144.02%;
                    margin-bottom: 24rem;

                    &::before {
                        width: 128rem;
                        height: 124rem;
                    }

                    &::after {
                        width: 128rem;
                        height: 124rem;
                        left: -105rem;
                    }
                }

                &__date {
                    font-size: 18rem;
                    line-height: 22rem;
                }
            }
        }

        &-pic {
            width: 287rem;
            margin: 0 auto;
            max-width: 287rem;

            .swiper-container {
                min-width: 0;
                overflow: visible;
            }

            &-item {
                img {
                    width: 100%;
                }

                &__not {
                    width: 255rem;
                    left: 17rem;
                    bottom: 14rem;
                    padding: 21rem 8rem 11rem;
                    border-radius: 22rem;

                    &-title {
                        font-size: 18.4312rem;
                        line-height: 20rem;
                        margin-bottom: 18rem;
                    }

                    &-btn {
                        width: 221rem;
                        height: 33rem;
                        font-size: 12.0512rem;
                        line-height: 16rem;
                        margin-bottom: 21rem;
                    }

                    &-img-1 img {
                        width: 72rem;
                    }

                    &-img-2 img {
                        width: 53rem;
                    }
                }
            }
        }
    }

    .contact {
        padding-top: 70rem;
        padding-bottom: 70rem;

        h2 {
            max-width: 522rem;
            margin: 0 auto 26rem;
            font-size: 40rem;
            line-height: 48rem;
        }

        p {
            max-width: 545rem;
            font-size: 24rem;
            line-height: 29rem;
            margin-bottom: 100rem;
        }

        form {
            max-width: 540rem;

            .input__placeholder {
                font-size: 18rem;
                line-height: 150%;
            }

            input {
                height: 43rem;
                font-size: 22rem;
                line-height: 150%;
                margin-bottom: 31rem;
            }

            .checkbox {
                margin-bottom: 80rem;

                span {
                    font-size: 20rem;
                    line-height: 20rem;
                }
            }

            .checkbox__icon {
                margin-right: 16rem;
            }

            .bnt {
                width: 261rem;
                height: 61rem;
            }
        }

        &-social {
            margin-top: 50rem;
        }

        &:after {
            display: none;
        }
    }

    .footer {
        .container {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-middle {
            order: 1;
            width: 100%;
            padding-top: 0;
            margin-top: 62rem;
        }

        &:after {
            background-size: 100% 375rem;
        }

        &-left .logo img {
            width: 130rem;
        }
    }

    main {
        overflow: hidden;
    }

    h2 {
        font-size: 32rem;
        line-height: 39rem;
    }

    .btn {
        width: 261rem;
        height: 61rem;
        font-size: 16rem;
        line-height: 20rem;
    }



    .error404 {
        main {
            padding-top: 170rem;
            padding-bottom: 134rem;
            &:after {
                width: 767rem;
                height: calc(100% + 483rem + 361rem);
                top: -230rem;
                background: url(../images/common/404.jpg);
                background-repeat: no-repeat;
                background-size: 767rem auto;
                background-position: top center;
            }
            h1 {
                font-weight: 700;
                font-size: 40rem;
                line-height: 48rem;
                margin-bottom: 36rem;
            }
        }
    }
}



@media (max-width: 575px) {
    html {
        font-size: 1px;
        position: relative;
    }

    .container {
        padding-right: 27rem;
        padding-left: 27rem;
    }

    .header {
        padding-top: 20rem;
        padding-bottom: 60rem;
        position: static;

        .container {
            flex-wrap: wrap;
        }

        .search {
            margin-top: 35rem;

            input {
                width: 100%;
                height: 82rem;
                font-size: 22rem;
                line-height: 150%;
            }

            span {
                font-size: 22rem;
                line-height: 150%;
            }
        }

        &-left {
            margin: 0 auto 35rem;
            width: 100%;
            display: flex;
            justify-content: center;
            max-width: 100%;

            .logo {
                width: 110rem;
                max-width: 110rem;
                margin: 0 auto;
                padding-top: 0;
            }

        }

        &-right {
            padding-top: 0rem;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
           
            top: 0;
            &.sticky {
                position: fixed;
                top: 0rem;
                left: 0rem;
                width: 100%;
                padding: 10rem 27rem;
                background: rgba(27, 40, 70, 0.9);
                backdrop-filter: blur(8px);
                z-index: 10;
            }
            ul {
                width: 140rem;
                img {
                    width: 34rem;
                }
            }
        }

        .nav {
            position: fixed;
            background: rgba(27, 40, 70, 0.9);
            backdrop-filter: blur(8px);
            border-radius: 16rem;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100vh - calc(100vh - 100%));
            z-index: 100;
            padding: 50rem;
            display: none;
            padding-top: 0;
            &-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: calc(100% + 100rem);
                margin: 0 -50rem;
                position: relative;
                z-index: 2;
                background: rgba(27, 40, 70, 1);
                padding: 50rem 50rem 20rem;
                &__title {
                    font-weight: 700;
                    font-size: 26rem;
                    line-height: 31rem;
                    color: #FFFFFF;
                }

                &__close {
                    width: 24rem;
                    height: 24rem;
                }

                &__back {
                    display: none;
                    align-items: center;
                    cursor: pointer;

                    &.active {
                        display: flex;

                        +.nav-top__title {
                            display: none;
                        }
                    }

                    svg {
                        width: 16rem;
                        margin-right: 62rem;
                    }

                    span {
                        font-weight: 700;
                        font-size: 26rem;
                        line-height: 31rem;
                        color: #FFFFFF;
                    }
                }
            }
            >div>ul {
                flex-direction: column;
                overflow: auto;
                >li {
                    margin-bottom: 30rem;
                    margin-right: 0;
                    a {
                        width: 100%;
                        display: block;
                        
                    }
                    >ul {
                        position: fixed;
                        background: rgba(27, 40, 70, 0.9);
                        backdrop-filter: blur(8px);
                        border-radius: 16rem;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: calc(100vh - calc(100vh - 100%));
                        max-height: calc(100vh - calc(100vh - 100%));
                        z-index: 100;
                        padding: 141rem 50rem 50rem;
                        overflow: auto;
                        > li {
                            margin-bottom: 30rem;
                        }
                    }
                }
            }
        }
        &-left .menu > li.menu-item-has-children > a {
            padding: 0rem 55rem 0rem 0rem;
        }
        &-left .menu > li.menu-item-has-children li.menu-item-has-children > ul {
            position: static;
            background: rgba(27, 40, 70, 1);
            border-radius: 16rem;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 100;
            padding: 0;
            padding-top: 30rem;
            >li {
                margin-bottom: 30rem;
            }
            
            
          
        }
        &-left {
            .menu > li.menu-item-has-children li.menu-item-has-children > a:after {
                transform: translateY(-50%) rotate(0deg);
                transition: 0.3s;
            }
            .menu > li.menu-item-has-children li.menu-item-has-children.active > a:after {
                transform: translateY(-50%) rotate(180deg);
            }
        }
        &-left .menu > li:hover > ul {
            display: none;
        }
        &-left .menu > li.active > ul {
            display: block;
        }
        &-left .menu > li.menu-item-has-children li.menu-item-has-children:hover > ul {
            display: none;
        }
        &-left .menu > li.menu-item-has-children li.menu-item-has-children.active > ul {
            display: block;
        }
        &-burger {
            display: flex;
            align-items: center;

            svg {
                width: 18rem;
                margin-right: 20rem;
            }

            span {
                font-weight: 700;
                font-size: 26rem;
                line-height: 31rem;
                color: #FFFFFF;
            }
        }

        &__phone {
            margin-bottom: 40rem;
            display: none;

            &--mobile {
                display: flex;
                justify-content: center;
                margin: 0 auto 22rem;
            }
        }
    }

    .thankyou {
        padding-bottom: 40rem;

        .hero-left {

            max-width: 100%;
            width: 100%;
        }

        .container {
            flex-direction: column;
        }

        h1 {
            margin-bottom: 41rem;

        }

        p {
            margin-bottom: 60rem;
        }

    }

    .hero {
        padding-bottom: 40rem;

        .container {
            flex-direction: column;
        }

        &-left {
            max-width: 100%;
            width: 100%;

            h1 {

                margin-bottom: 41rem;
                letter-spacing: -1px;
            }

            p {
                margin-bottom: 60rem;
            }

            .btn {
                margin: 0 auto;
            }

        }

        &-right {
            display: none;
        }

        &-img-mobile {
            display: block;

            img {
                width: 100%;
                margin-bottom: 41rem;
            }
        }

    }


    .video {
        padding-top: 37rem;
        padding-bottom: 88rem;
        background: url(../images/common/video-bg.svg);
        background-size: 120% auto;

        h2 {
            margin-bottom: 50rem;
        }
    }


    .product {
        &-top {
            margin-bottom: 30rem;
        }
        .container {
            background: #FFFFFF;
            border-radius: 0rem;
            padding: 80rem 30rem 54rem;
        }

        &__title {
            font-size: 24rem;
            line-height: 29rem;
        }


        &-slide {
            display: grid;
            grid-template-columns: 100%;
            grid-gap: 20rem;
            margin-bottom: 37rem;
        }
        &-thumb {
            width: 100%;
            height: 100rem;
            order: 1;
            &__img {
                width: 100%;
                height: 100%;
            }
            .swiper-slide {
                padding-bottom: 0;
                padding-right: 10rem;
            }
        }

        &-gallery {
            width: 100%;
            height: 317rem;
            &__img {
                padding: 46rem;
            }
            .prev {
                left: 0rem;
            }
            .next {
                right: 0rem;
            }
        }


        &-desc {
            &-nav {
                margin-bottom: 50rem;
                ul {
                    display: none;
                }
            }
            .swiper-container {
                display: block;
                a {
                    background: #2F80ED;
                    border-radius: 16rem;
                    width: 100%;
                    height: 60rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 700;
                    font-size: 16rem;
                    line-height: 144.02%;
                    color: #FCFCFC;
                }
                .prev {
                    left: 40rem;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    outline: none;
                    z-index: 1;
                    svg {
                        width: 24rem;
                        height: 24rem;
                        path {
                            stroke: #FCFCFC;
                        }
                    }
                }
                .next {
                    right: 40rem;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    outline: none;
                    z-index: 1;
                    svg {
                        width: 24rem;
                        height: 24rem;
                        path {
                            stroke: #FCFCFC;
                        }
                    }
                }
            }

            &-item {
                margin-bottom: 30rem;

                &__title {
                    font-size: 24rem;
                    line-height: 150%;
                    margin-bottom: 30rem;
                }
                ul {
                    margin-bottom: 20rem;
                }

                &__table {
                    margin-bottom: 30rem;
                }
            }

            &-tech {
                margin-bottom: 30rem;

                &-item {
                    margin-bottom: 20rem;
                    height: 86rem;

                    border: 2px solid rgba(47, 128, 237, 0.2);
                    align-items: center;
                    flex-direction: row;
                    justify-content: flex-start;
                    padding: 0 10rem 0 15rem;
                    width: 100%!important;
                    &__icon {
                        margin-right: 20rem;
                        margin-bottom: 0;
                    }
                }
            }

            .product-desc-tech__text {

                font-weight: 400;
                font-size: 12rem;
                line-height: 144.02%;
                color: #0C1938;

            }
        }
        &-mobile {
            &-top {
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: column-reverse;
                width: 100%;
                margin-bottom: 30rem;
            }

            .product-right__price {
                margin-bottom: 0;
                font-size: 34rem;
                line-height: 41rem;
            }
            .product-right__article {
                margin-bottom: 10rem;
            }
            &-action {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                margin-bottom: 30rem;
                .btn-red {
                    width: 100%;
                    margin-bottom: 20rem;
                }
                .btn {
                    width: 100%;
                }
            }
        }

        &-question {
            &-top {
                display: flex;
                align-items: center;

                justify-content: space-between;

                h3 {
                    font-size: 16rem;
                    line-height: 144.02%;
                    padding-left: 0;

                    &:after {
                        display: none;
                    }
                }
            }
        }
        &-delivery {
            &-wrap {
                grid-template-columns: 100%;
            }
            &-item {
                height: 73rem;
            }
        }
        &-bottom {
            &-left {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10rem;
                flex-direction: column;
                text-align: center;
                a {
                    margin-bottom: 10rem;
                    img {
                        width: 68rem;
                    }
                }
                strong {
                   margin-bottom: 10rem; 
                }
            }
            &-right {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                strong {
                    margin-bottom: 16rem;
                    
                }
            }
        }
    }
    .product-desc-tech-item span {
        font-size: 15rem;
    }
    .map {
        .container {
            padding-top: 40rem;
            padding-bottom: 45rem;
        }

        h2 {
            margin: 0 auto 24rem;
            max-width: 100%;
        }

        &-info {
            margin-bottom: 50rem;

            &__title {
                font-size: 20rem;
                line-height: 24rem;
                margin-bottom: 36rem;
            }

            p {
                max-width: 100%;
                font-size: 20rem;
                line-height: 24rem;
            }
        }

        &-block {
            width: 341rem;
            min-width: 341rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: auto;
            margin: 0 auto;
        }

        &-item {
            &--1 {
                width: 321rem;
                height: 215rem;
                position: relative;
                left: 0;
                top: 0;
                margin-bottom: 20rem;

                .map-profile {
                    &--1 {
                        width: 114rem;
                        top: 36rem;
                        left: 62rem;
                    }

                    &--2 {
                        width: 131rem;
                        top: -3rem;
                        left: 159rem;
                    }
                }

                .map-item__flag {
                    display: none;
                }

            }

            &--2 {
                width: 341rem;
                height: 233rem;
                position: relative;
                left: 0;
                top: 0;
                bottom: 0;
                order: 1;

                .map-item__flag {
                    display: none;
                }

                .map-profile {
                    &--1 {
                        width: 104rem;
                        top: 71rem;
                        left: 26rem;
                    }

                    &--2 {
                        width: 92rem;
                        top: 46rem;
                        left: 130rem;
                    }

                    &--3 {
                        width: 129rem;
                        top: 41rem;
                        left: 212rem;
                    }
                }
            }

            &--3 {
                width: 316rem;
                height: 191rem;
                bottom: 0;
                position: relative;
                margin-bottom: 0;

                .map-item__flag {
                    display: none;
                }

                .map-profile {
                    &--1 {
                        width: 104rem;
                        top: 0rem;
                        left: 55rem;
                    }

                    &--2 {
                        width: 112rem;
                        top: -6rem;
                        left: 184rem;
                    }
                }
            }
        }
    }

    .we {
        padding-top: 45rem;
        padding-bottom: 45rem;

        h2 {
            margin-bottom: 60rem;
        }

        &-wrapper {
            grid-template-columns: 1fr;
            max-width: 255rem;
            grid-column-gap: 65rem;
            grid-row-gap: 40rem;
            margin: 0 auto 73rem;
        }

        &-item {
            &__icon {
                width: 47rem;
                margin-bottom: 30rem;
            }

            h3 {
                font-size: 28rem;
                line-height: 34rem;
                margin-bottom: 18rem;
            }
        }

        &-slider {
            max-width: 278rem;
            margin: 0 auto;

            .swiper-container {
                overflow: visible;
            }

            &-item {
                padding: 17rem 19rem;

                &__img {
                    margin-bottom: 16rem;

                    img {
                        height: 196rem;
                    }

                }

            }

            &-nav {
                margin-top: 35rem;
                justify-content: flex-start;
            }
        }
    }

    .how {
        padding-top: 40rem;
        padding-bottom: 40rem;

        &-right {
            width: 100%;
            margin-bottom: 40rem;
        }

        &__title {
            font-size: 32rem;
            line-height: 39rem;
            margin-bottom: 40rem;
        }

        &-items {
            margin-right: 0;
            max-width: 100%;

        }
        &-item  {
            padding-left: 15rem;
            padding-right: 10rem;
        }

        &-item p {
            font-size: 16rem;
            line-height: 19rem;
        }

    }

    .reviews {
        padding-top: 40rem;
        padding-bottom: 50rem;

        .container {
            padding: 0;
        }

        &-nav {
            margin: 0 auto 48rem;
        }

        &-info {
            &-item {
                padding-top: 85rem;
                padding-bottom: 72rem;
                padding-right: 39rem;
                padding-left: 32rem;

                &__top {
                    font-size: 12rem;
                    line-height: 15rem;
                    margin-bottom: 17rem;
                }

                p {
                    font-size: 16rem;
                    line-height: 144.02%;
                    margin-bottom: 17rem;

                    &::before {
                        width: 112rem;
                        height: 100rem;
                        right: -54rem;
                        bottom: -95rem;
                    }

                    &::after {
                        width: 112rem;
                        height: 100rem;
                        left: -70rem;
                        top: -110rem;
                    }
                }

                &__date {
                    font-size: 12rem;
                    line-height: 15rem;
                }
            }
        }

        &-pic {
            width: 268rem;
            margin-left: 28rem;
            max-width: 268rem;

            &-item {
                &__not {
                    width: 238rem;
                    left: 15rem;
                    bottom: 14rem;
                    padding: 19rem 7rem 11rem;
                    border-radius: 22rem;

                    &-title {
                        font-size: 17.2141rem;
                        line-height: 19rem;
                        margin-bottom: 16rem;
                    }

                    &-btn {
                        width: 206rem;
                        height: 30rem;
                        font-size: 11.2554rem;
                        line-height: 15rem;
                        margin-bottom: 20rem;
                    }

                    &-img-1 img {
                        width: 67rem;
                    }

                    &-img-2 img {
                        width: 49rem;
                    }
                }
            }

        }
    }

    .contact {
        padding-top: 50rem;
        padding-bottom: 79rem;

        h2 {
            max-width: 100%;
            margin: 0 auto 19rem;
            font-size: 24rem;
            line-height: 29rem;
        }

        p {
            max-width: 100%;
            font-size: 16rem;
            line-height: 19rem;
            margin-bottom: 42rem;
        }

        form {
            max-width: 320rem;

            .btn {
                width: 261rem;
            }

            .checkbox span {
                letter-spacing: -0.5px;
            }
        }
    }

    .footer {
        .container {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        &-left .logo img {
            width: 110rem;
        }

        &-right {
            margin-left: 0;
            padding-top: 0;
            margin-top: 40rem;

            p {
                text-align: left;
            }
        }

        &-middle {
            margin-top: 40rem;
        }

        &:after {
            background-size: 100% 538rem;
        }
    }

    h2 {
        font-size: 24px;
        line-height: 29px;

    }

    .error404 {
        main {
            padding-top: 150rem;
            padding-bottom: 134rem;

            &:after {
                width: 100%;
                height: calc(100% + 483rem + 361rem);
                top: -150rem;
                background: url(../images/common/404.jpg);
                background-repeat: no-repeat;
                background-size: 600rem auto;
                background-position: top center;
            }

            h1 {
                font-weight: 700;
                font-size: 40rem;
                line-height: 48rem;
                margin-bottom: 36rem;
                text-align: center;
            }
        }
    }
}