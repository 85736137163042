// Colors
$white: #ffffff;
$black: #000000;
$blackSecond:#190e1e;
$lightblue: #00a2e8;
$blue: #007eff;
$yellow: #ffa800;
$grey: #e3d7e9;
// Sizes
$base-size: 14px;
$h1-size: 24px;

$tablet: 768;
$large: 1024;
$desktop: 1280;

$content: 1720rem;

// Fonts
$openSans: 'Open Sans', sans-serif;