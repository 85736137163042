@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/sprite";
//@include sprites($spritesheet-sprites);

html {
  font-size: 1px;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: 'Inter';
  background: #0C1938;
  font-size: 16px;
  overflow-x: hidden;
}

*,
*::before,
*::after { box-sizing: border-box; }


body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
a {
  text-decoration: none;
}
ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select { font: inherit; }

audio,
canvas,
iframe,
img,
svg,
video,
textarea { vertical-align: middle; }

textarea { resize: none; }

section { position: relative; }

input,
select,
button { outline: none; }

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content { flex: 1 0 auto; }

.container {
  margin: 0 auto;
  max-width: $content;
  width: 100%;
}

//Модули
@import "modules/modules";
@import "index";
@import "media";
