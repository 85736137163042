header {
    flex: 0 0 auto;
}

.header {
    padding-top: 20rem;
    padding-bottom: 31rem;

    &-burger {
        display: none;
    }

    .nav-top {
        display: none;
    }

    .container {
        display: flex;

    }

    &-left {
        margin-right: 136rem;
        max-width: 159rem;

        .logo {
            width: 159rem;
            display: block;
            padding-top: 5rem;
        }

        .menu {
            display: flex;
            margin-top: 52rem;
            position: relative;
            z-index: 1;

            >li {
                margin-right: 52rem;
                -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
                -webkit-focus-ring-color: rgba(0, 0, 0, 0);
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                >a {
                    font-weight: 700;
                    font-size: 26rem;
                    line-height: 31rem;
                    display: flex;
                    color: #FFFFFF;
                    white-space: nowrap;
                    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
                    -webkit-focus-ring-color: rgba(0, 0, 0, 0);
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    padding: 16rem 0 12rem;

                    &:hover {
                        color: #EF382A;
                    }
                }

                &.menu-item-has-children {

                    cursor: pointer;
                    position: relative;

                    &:hover {
                        >a {
                            color: #FFFFFF;
                            border-radius: 16px 16px 0px 0px;
                            background: rgba(27, 40, 70, 0.9);
                            backdrop-filter: blur(8px);
                        }
                    }

                    >a {
                        padding-right: 38rem;
                        position: relative;
                        padding: 16rem 55rem 12rem 40rem;

                        &:hover {
                            color: #FFFFFF;
                        }

                        &:after {
                            position: absolute;
                            content: '';
                            width: 18rem;
                            height: 18rem;
                            right: 18rem;
                            top: 50%;
                            transform: translateY(-50%);
                            background: url(../images/common/arrow.svg);
                            background-size: cover;
                        }
                    }

                    li {
                        &.menu-item-has-children {
                            cursor: pointer;
                            width: calc(100% + 80rem);
                            margin-right: -40rem;
                            margin-left: -40rem;
                            padding-right: 40rem;
                            padding-left: 40rem;

                            >a {
                                padding-right: 38rem;
                                position: relative;
                                &:hover {
                                    color: #FFFFFF;
                                }
                                
                                &:after {
                                    position: absolute;
                                    content: '';
                                    width: 18rem;
                                    height: 18rem;
                                    right: 0;
                                    top: 50%;
                                    transform: translateY(-50%) rotate(-90deg);
                                    background: url(../images/common/arrow.svg);
                                    background-size: cover;
                                }
                            }

                            >ul {
                                display: none;
                                position: absolute;
                                background: rgba(27, 40, 70, 0.9);
                                backdrop-filter: blur(8px);

                                border-radius: 16rem;
                                min-height: 100%;
                                top: 0;
                                left: 100%;
                                padding: 50rem 40rem;
                                z-index: 1;

                                li {
                                    margin-bottom: 20rem;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }

                                a {
                                    font-weight: 700;
                                    font-size: 26rem;
                                    line-height: 31rem;
                                    color: #FFFFFF;
                                    white-space: nowrap;
                                    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
                                    -webkit-focus-ring-color: rgba(0, 0, 0, 0);
                                    -webkit-touch-callout: none;
                                    -webkit-user-select: none;
                                    -khtml-user-select: none;
                                    -moz-user-select: none;
                                    -ms-user-select: none;
                                    user-select: none;

                                    &:hover {
                                        color: #EF382A;
                                    }
                                }
                            }

                            &.active {
                                >a {
                               
                                    color: #FFFFFF;
                                }

                                >ul {
                                    display: block;
                                }
                            }

                            &:hover {
                                >ul {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                >ul {
                    display: none;
                    position: absolute;
                    background: rgba(27, 40, 70, 0.9);
                    backdrop-filter: blur(8px);
                    border-radius: 0px 16px 16px 16px;
                    top: 100%;
                    left: 0;
                    padding: 20rem 40rem;
                    z-index: 1;



                    li {
                        margin-bottom: 20rem;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    a {
                        font-weight: 700;
                        font-size: 26rem;
                        line-height: 31rem;
                        color: #FFFFFF;
                        white-space: nowrap;
                        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
                        -webkit-focus-ring-color: rgba(0, 0, 0, 0);
                        -webkit-touch-callout: none;
                        -webkit-user-select: none;
                        -khtml-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;

                        &:hover {
                            color: #EF382A;
                        }
                    }
                }

                &.active {
                    >a {
                        color: #FFFFFF;
                    }

                    >ul {
                        display: block;
                    }
                }

                &:hover {
                    >ul {
                        display: block;
                    }
                }
            }

            .current_page_item>a {
                color: #EF382A;
            }
        }
    }
    
    .search {
        padding-top: 20rem;

        .input {
            position: relative;
        }

        input {
            width: 734rem;
            height: 49rem;
            background: #1B2846;
            border-radius: 16rem;
            border: none;
            box-shadow: none;
            padding-left: 16rem;
            font-weight: 400;
            font-size: 22rem;
            line-height: 150%;
            color: #FCFCFC;

            &::placeholder {
                color: #586179;
            }

            &:focus~span,
            &:valid~span {
                display: none;
            }
        }

        span {
            font-weight: 400;
            font-size: 22rem;
            line-height: 150%;
            color: #586179;
            position: absolute;
            left: 16rem;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
            user-select: none;
        }
    }

    &-right {
        margin-left: auto;
        padding-top: 40rem;

        ul {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 266rem;

            img {
                width: 40rem;
            }
        }
    }

    &__phone {
        display: flex;
        align-items: center;
        margin-bottom: 31rem;

        &--mobile {
            display: none;
        }

        img {
            width: 24rem;
            margin-right: 24rem;
        }

        span {
            font-weight: 700;
            font-size: 25rem;
            line-height: 30rem;
            color: #FFFFFF;
        }
    }
}