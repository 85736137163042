footer { flex: 0 0 auto; }
.footer {
    padding-bottom: 95rem;
    position: relative;
    z-index: 0;
    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: url(../images/common/footer.png);
        left: 0;
        bottom: 0;
        background-size: 100% auto;
        background-position: bottom;
        background-repeat: no-repeat;
        z-index: -1;
    }
    .container {
        display: flex;
    } 
    &-left {
        margin-right: 173rem;
        .logo {
            img {
                width: 159rem;
            }
            margin-bottom: 28rem;
            display: block;
        }
        ul {
            li {
                margin-bottom: 19rem;
            }
            a {
                font-weight: 500;
                font-size: 24rem;
                line-height: 20rem;
                color: #575F78;
                white-space: nowrap;
            }
        }
    }
    &-middle {
        padding-top: 62rem;
        max-width: 779rem;
        ul {
            display: flex;
            margin-bottom: 30rem;
            li {
                margin-right: 15rem;
            }
            img {
                width: 44rem;
            }
        }
        p {
            font-weight: 400;
            font-size: 14rem;
            line-height: 133.02%;
            color: #7996DC;
        }
    }
    &-right {
        margin-left: auto;
        padding-top: 62rem;
        ul {
            display: flex;
            margin-bottom: 30rem;
            li {
                margin-right: 5rem;
                &:last-child {
                    margin-right: 0;
                }
                img {
                    width: 69rem;
                }
            }
        }
        p {
            font-weight: 500;
            font-size: 18rem;
            line-height: 20rem;
            text-align: right;
            color: #FFFFFF;
        }
    }
}