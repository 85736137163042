// main {
//     overflow: hidden;
// }

.hero {
    padding-bottom: 120rem;

    .container {
        display: flex;
        align-items: flex-end;
    }

    &-img-mobile {
        display: none;
    }
    &-left {
        max-width: 784rem;
        margin-right: 75rem;
        h1 {
            font-weight: 700;
            font-size: 90rem;
            line-height: 109rem;
            color: #FFFFFF;
            margin-bottom: 45rem;
            span {
                color: #03C623;
            }
        }
        p {
            font-weight: 500;
            font-size: 30rem;
            line-height: 36rem;
            color: #FFFFFF;
            margin-bottom: 152rem;

            span {
                color: #03C623;
            }
        }

    }

    &-right {
        img {
            max-width: 739rem;
            width: auto;
            max-height: 90vh;
        }
    }
}

.video {
    padding-top: 77rem;
    padding-bottom: 181rem;
    background: url(../images/common/video-bg.svg);
    background-size: 1920rem auto;
    background-position: center 127rem;
    background-repeat: no-repeat;

    h2 {
        margin-bottom: 140rem;
    }

    &-wrapper {
        position: relative;
        padding-bottom: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.product {
  

    &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 50rem;
    }
    &.night {
        .container {
            background: #10254C;
        }
        .product__title {
            color: #FCFCFC;
        }
        .product-desc-nav ul a {
            color: #FCFCFC;
        }
        .product-desc-item__title {
            color: #FCFCFC;
        }
        .product-desc-item p {
            color: #FCFCFC;
        }
        .product-desc-item ul li {
            color: #FCFCFC; 
        }
        .product-desc-item-line span {
            color: #FCFCFC;
        }
        .product-desc-tech-item strong {
            color: #FCFCFC;
        }
        .product-desc-tech-item span {
            color: #FCFCFC;
        }
        .product-question-top h3 {
            color: #FCFCFC;
        }
        .product-right__info {
            color: #FCFCFC;
        }
        .product-right__article {
            color: #FCFCFC;
        }
        .product-right__price {
            color: #FCFCFC;
        }
        .product-delivery__title {
            color: #FCFCFC;
        }
        .product-delivery-item span {
            color: #FCFCFC;
        }
        .product-yamaha p {
            color: #FCFCFC;
        }
        .product-bottom-left strong, .product-bottom-left span, .product-bottom-right strong, .product-bottom-right span {
            color: #FCFCFC;
        }
    }
    &-switch {
        &-wrap {
            width: 118rem;
            height: 57rem;
            background: #264577;
            border-radius: 30rem;
            position: relative;
            cursor: pointer;
            transition: 0.3s;
        }
        input {
            opacity: 0;
            position: absolute;
            pointer-events: none;
            user-select: none;
        }
        input:checked + .product-switch-wrap {
            background: #F7DA4A;
            .product-switch-icon {
                transform: translateX(60rem);
               
                .product-switch__night {
                    opacity: 0;
                }
                .product-switch__day {
                    opacity: 1;
                }
            }
        }
        &-icon {
            position: absolute;
            width: 49rem;
            height: 49rem;
            top: 4rem;
            left: 4rem;
            transition: 0.3s;
            svg {
                width: 100%;
                height: 100%;
            }
        }
        &__night {
            position: absolute;
            transition: 0.3s;
        }
        &__day {
            position: absolute;
            opacity: 0;
            transition: 0.3s;
        }
    }
    .container {
        background: #FFFFFF;
        border-radius: 16rem;
        padding: 100rem 140rem 92rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__title {
        font-weight: 700;
        font-size: 50rem;
        line-height: 61rem;
        color: #1B2846;
    }

    &-slide {
        display: grid;
        grid-template-columns: 100rem 759rem;
        grid-gap: 26rem;
        margin-bottom: 37rem;
    }

    &-thumb {
        width: 100%;
        height: 480rem;

        .swiper-container {
            height: 100%;
        }

        .swiper-wrapper {
            height: 100%;
        }
        .swiper-slide {
            padding-bottom: 26rem;
        }
        &__img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20rem;
            background: #FFFFFF;
            border: 2px solid rgba(88, 97, 121, 0.2);
            border-radius: 16rem;
            
            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
        .swiper-slide-active {
            .product-thumb__img {
                border: 2rem solid #EF382A;
            }
        }
    }
    &-gallery {
        width: 100%;
        height: 480rem;
        position: relative;
        background: #FFFFFF;
        border-radius: 16rem;
        &__sale {
            position: absolute;
            top: 30rem;
            left: 30rem;

            height: 38rem;
            padding: 0px 18rem;
            background: #2F80ED;
            border-radius: 8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            font-weight: 700;
            font-size: 10rem;
            line-height: 11rem;
            color: #FFFFFF;
        }
        .swiper-container {
            height: 100%;
            max-width: 100%;
            overflow: hidden;
        }

        .swiper-wrapper {
            width: 100%;
        }

        &__img {
            max-width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 36rem;
         
            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .prev {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 30rem;
            cursor: pointer;
            outline: none;
            z-index: 1;

            svg {
                width: 32rem;
                height: 32rem;
            }
        }

        .next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 30rem;
            cursor: pointer;
            outline: none;
            z-index: 1;

            svg {
                width: 32rem;
                height: 32rem;
            }
        }
    }

    &-left {
        max-width: 919rem;
        margin-bottom: 60rem;
    }

    &-desc {
        &-nav {
            margin-bottom: 50rem;
            .swiper-container {
                display: none;
            }
            ul {
                display: flex;
                justify-content: space-between;
                background: radial-gradient(75.67% 115.9% at 110.46% -24.69%, rgba(255, 149, 181, 0.3) 0%, rgba(255, 149, 181, 0) 100%)
                    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
                    , rgba(47, 128, 237, 0.06);
                border-radius: 16rem;

                a {

                    border-radius: 16rem;
                    height: 60rem;
                    padding: 0 40rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 700;
                    font-size: 16rem;
                    line-height: 144.02%;
                    color: #222222;
                }

                li.active {
                    a {
                        background: #2F80ED;
                        color: #FCFCFC;
                    }
                }
            }
        }

        &-item {
            margin-bottom: 50rem;
            &:last-child {
                margin-bottom: 20rem;
            }
     
            &__title {
                font-weight: 600;
                font-size: 36rem;
                line-height: 44rem;
                color: #222222;
                margin-bottom: 30rem;
            }

            p {
                margin-bottom: 20rem;
                font-weight: 500;
                font-size: 16rem;
                line-height: 144.02%;
                color: #222222;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            ul {
                margin-bottom: 20rem;

                li {
                    padding-left: 22rem;
                    position: relative;
                    margin-bottom: 10rem;
                    font-weight: 500;
                    font-size: 16rem;
                    line-height: 144.02%;
                    color: #222222;

                    &::after {
                        position: absolute;
                        content: '';
                        width: 12rem;
                        height: 12rem;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        border: 2px solid #2F80ED;
                        border-radius: 50%;
                    }

                    span {
                        font-weight: 700;
                    }
                }
            }

            &__table {
                margin-bottom: 50rem;

            }

            &-line {
                display: flex;
                justify-content: space-between;
                height: 43rem;
                background: rgba(47, 128, 237, 0.06);
                border-radius: 16rem;
                align-items: center;
                padding: 0 20rem;

                &:nth-child(even) {
                    background: transparent;
                }

                span {
                    font-weight: 500;
                    font-size: 16rem;
                    line-height: 144.02%;
                    color: #222222;
                }
            }

        }

        &-tech {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 30rem;

            &-item {
                margin-bottom: 20rem;
                height: 106rem;

                border: 3px solid rgba(47, 128, 237, 0.2);
                border-radius: 16rem;
                display: flex;
                align-items: center;
                padding: 0 30rem;
                width: 48.91%;
                
                &__icon {
                    width: 40rem;
                    height: 40rem;
                    min-width: 40rem;
                    margin-right: 20rem;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                strong {
                    font-weight: 700;
                    font-size: 16rem;
                    line-height: 144.02%;
                    color: #0C1938;
                    display: block;
                }

                span {
                    font-weight: 400;
                    font-size: 16rem;
                    line-height: 144.02%;
                    color: #0C1938;
                    display: block;
                }

                a {
                    font-weight: 400;
                    font-size: 16rem;
                    line-height: 144.02%;
                    color: #2F80ED;
                }
                &.garanty {
                    &:nth-child(1) {
                        width: 25.35%;
                    }
    
                    &:nth-child(2) {
                        width: 35.14%;
                    }
    
                    &:nth-child(3) {
                        width: 35.14%;
                    }
    
                    &:nth-child(4) {
                        width: 48.91%;
                    }
    
                    &:nth-child(5) {
                        width: 48.91%;
                    }
                }
            }
        }
        .product-desc-tech__text  {
      
                font-weight: 400;
                font-size: 12rem;
                line-height: 144.02%;
                color: #0C1938;
          
        }
    }

    &-question {
        &-item {
            padding: 30rem;
            border: 3px solid rgba(47, 128, 237, 0.0);
            background: rgba(47, 128, 237, 0.06);
            border-radius: 16rem;
            margin-bottom: 20rem;
            transition: 0.3s;
            cursor: pointer;
            p {
                font-weight: 400;
                font-size: 16rem;
                line-height: 144.02%;
                color: #0C1938;
                margin-top: 20rem;
                display: none;
            }
            &.active {
                background: transparent;
                border: 3px solid rgba(47, 128, 237, 0.2);
                .product-question-top__arrow {
                    transform: rotate(180deg);
                }
            }
        }

        &-top {
            display: flex;
            align-items: center;
            
            justify-content: space-between;
            h3 {
                position: relative;
                padding-left: 26rem;
                font-weight: 600;
                font-size: 20rem;
                line-height: 144.02%;
                color: #333333;
                &:after {
                    position: absolute;
                    content: '';
                    width: 16rem;
                    height: 16rem;
                    background: url(../images/common/question-circle.svg);
                    background-size: cover;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            &__arrow {
                min-width: 16rem;
                margin-left: 10rem;
                width: 16rem;
                height: 16rem;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s;
                
                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &-right {
        max-width: 411rem;
        &__info {
            font-weight: 400;
            font-size: 12rem;
            line-height: 15rem;
            color: #586179;
            margin-bottom: 20rem;
        }
        &__article {
            font-weight: 700;
            font-size: 26rem;
            line-height: 31rem;
            color: #222222;
            margin-bottom: 40rem;
        }
        &__price {
            font-weight: 700;
            font-size: 50rem;
            line-height: 61rem;
            color: #222222;
            margin-bottom: 30rem;
        }
        
    }
    &-mobile {
        display: none;
    }
    .btn-red  {
        width: 100%;
        height: 71rem;
        background: #EF382A;
        border-radius: 16rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30rem;
        font-weight: 700;
        font-size: 20rem;
        line-height: 20rem;
        color: #FCFCFC;
    }
    .btn {
        width: 100%;
        height: 71rem;
        background: transparent;
        border: 2px solid #2F80ED;
        font-weight: 700;
        font-size: 20rem;
        line-height: 20rem;
        color: #2F80ED;
        margin-bottom: 40rem;
    }
    &-delivery {
        &__title  {
            font-weight: 600;
            font-size: 20rem;
            line-height: 144.02%;
            color: #222222;
            margin-bottom: 20rem;
        }
        &-item {
            display: flex;
            align-items: center;
            background: rgba(47, 128, 237, 0.06);
            border-radius: 16rem;
            height: 80rem;
            padding: 0 30rem;
            margin-bottom: 20rem;
            svg {
                width: 40rem;
                height: 40rem;
                margin-right: 20rem;
            }
            span {
                font-weight: 500;
                font-size: 16rem;
                line-height: 144.02%;
                color: #222222;
            }
        }
    }
    &-yamaha {
        background: radial-gradient(75.67% 115.9% at 110.46% -24.69%, rgba(255, 149, 181, 0.3) 0%, rgba(255, 149, 181, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, rgba(47, 128, 237, 0.06);
        border-radius: 16rem;
        padding: 30rem;
        img {
            height: 30rem;
            width: auto;
            margin-bottom: 20rem;
        }
        p {
            font-weight: 500;
            font-size: 16rem;
            line-height: 144.02%;
            color: #222222;
            margin-bottom: 20rem;
        }
        a {
            font-weight: 500;
            font-size: 16rem;
            line-height: 144.02%;
            color: #2F80ED;
           
            position: relative;
            &::after {
                position: absolute;
                content: '';
                width: 15rem;
                height: 15rem;
                background: url(../images/common/link-arrow.svg);
                background-size: cover;
                right: -25rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    &-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30rem;
        border: 6rem solid #FFC500;
        border-radius: 16rem;
        width: 100%;
        &-left {
            display: flex;
            align-items: center;
            a {
                margin: 0 22rem;
                img {
                    width: 68rem;
                }
            }
            strong {
                font-weight: 600;
                font-size: 20rem;
                line-height: 144.02%;
                color: #1B2846;
            }
            span {
                font-weight: 400;
                font-size: 20rem;
                line-height: 144.02%;
                color: #1B2846;
            }
        }
        &-right  {
            strong {
                font-weight: 600;
                font-size: 20rem;
                line-height: 144.02%;
                color: #1B2846;
            }
            span {
                font-weight: 500;
                font-size: 16rem;
                line-height: 144.02%;
                color: #1B2846;
                margin-left: 16rem;
            }
        } 
    }
}

.map {
    .container {
        position: relative;
        padding-top: 120rem;
        padding-bottom: 120rem;

        &:after {
            position: absolute;
            content: '';
            width: 2048rem;
            height: 2048rem;
            background: url(../images/common/Ellipse.webp);
            background-size: cover;
            left: -598rem;
            top: -452rem;

            user-select: none;
            z-index: -1;
        }
    }

    &-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &-block {
        min-width: 965rem;
        width: 965rem;
        height: 746rem;
        position: relative;

    }

    &-item {
        position: absolute;

        path {
            transition: 0.3s ease-in-out;
        }

        &:hover {
            z-index: 1;

            .map-item__flag {
                opacity: 1;
            }
        }

        &--1 {
            width: 783rem;
            height: 486rem;
            top: 0;
            left: 66rem;

            &:hover {
                svg>path {
                    fill: #2C406D;
                    stroke: #586179;
                }

            }

            svg {
                width: 100%;
                height: 100%;
            }

            .map-profile {
                &--1 {
                    width: 144rem;
                    top: 232rem;
                    left: 269rem;

                    .map-profile__name {
                        font-weight: 500;
                        font-size: 13.8795rem;
                        line-height: 17rem;
                        top: -18rem;
                        left: -10rem;
                    }
                }

                &--2 {
                    width: 166rem;
                    top: 109rem;
                    left: 450rem;

                    .map-profile__name {
                        font-weight: 500;
                        font-size: 16rem;
                        line-height: 19rem;
                        top: -17rem;
                        left: 22rem;
                    }
                }
            }

            .map-item__flag {
                position: absolute;
                top: 305rem;
                left: 114rem;
                width: 52rem;
                opacity: 0;
                transition: 0.3s ease-in-out;
            }

        }

        &--2 {
            width: 494rem;
            height: 348rem;
            bottom: 0;
            left: 0;

            &:hover {
                path {
                    fill: #2C406D;
                    stroke: #586179;
                }

            }

            .map-item__flag {
                position: absolute;
                top: 195rem;
                left: 186rem;
                width: 52rem;
                opacity: 0;
                transition: 0.3s ease-in-out;
            }

            svg {
                width: 100%;
                height: 100%;
            }

            .map-profile {
                &--1 {
                    width: 131rem;
                    top: 124rem;
                    left: 24rem;

                    .map-profile__name {
                        font-weight: 500;
                        font-size: 12.6409rem;
                        line-height: 15rem;
                        top: -13rem;
                        left: 17rem;
                    }
                }

                &--2 {
                    width: 117rem;
                    top: 42rem;
                    left: 192rem;

                    .map-profile__name {
                        font-weight: 500;
                        font-size: 11.2771rem;
                        line-height: 14rem;
                        top: -10rem;
                        left: 15rem;
                    }
                }

                &--3 {
                    width: 164rem;
                    top: 81rem;
                    left: 330rem;

                    .map-profile__name {
                        font-weight: 500;
                        font-size: 15.8072rem;
                        line-height: 19rem;
                        top: -17rem;
                        left: 21rem;
                    }
                }
            }
        }

        &--3 {
            width: 507rem;
            height: 285rem;
            bottom: 84rem;
            right: 0rem;

            &:hover {
                svg>path {
                    fill: #2C406D;
                    stroke: #586179;
                }

            }

            .map-item__flag {
                position: absolute;
                top: 111rem;
                left: 41rem;
                width: 52rem;
                opacity: 0;
                transition: 0.3s ease-in-out;
            }

            svg {
                width: 100%;
                height: 100%;
            }

            .map-profile {
                &--1 {
                    width: 132rem;
                    top: 86rem;
                    left: 175rem;

                    .map-profile__name {
                        font-weight: 500;
                        font-size: 12.7229rem;
                        line-height: 15rem;
                        top: -13rem;
                        left: 17rem;
                    }
                }

                &--2 {
                    width: 143rem;
                    top: 9rem;
                    left: 306rem;

                    .map-profile__name {
                        font-weight: 500;
                        font-size: 16rem;
                        line-height: 19rem;
                        top: -16rem;
                        left: 11rem;
                    }
                }
            }
        }
    }

    &-profile {
        position: absolute;
        transition: 0.3s ease-in-out;
        cursor: pointer;

        &__name {
            position: absolute;
            color: #2F80ED;
            transition: 0.3s ease-in-out;
        }

        &:hover {
            transform: translateY(-10rem);
            z-index: 1;

            .map-profile__name {
                background: #FFFFFF;
                border-radius: 20rem;
                padding: 10rem;
                transform: translateY(-30rem) translateX(-20rem);
                font-size: 20rem;
                line-height: 24rem;
            }

            span {
                opacity: 1;
            }
        }

        span {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -56rem;
            display: flex;
            align-items: center;
            padding: 0 10rem;
            background: #FCFCFC;
            border-radius: 8rem;
            font-weight: 400;
            font-size: 19.0588rem;
            line-height: 29rem;
            color: #0C1938;
            width: max-content;
            white-space: nowrap;
            height: 45rem;
            opacity: 0;
            transition: 0.3s ease-in-out;
            z-index: -1;

            &:after {
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 6rem 7rem 6rem;
                border-color: transparent transparent #ffffff transparent;
                left: 50%;
                transform: translateX(-50%);
                top: -6rem;
            }

            img {
                width: 24rem;
                margin-right: 8rem;
            }
        }
    }

    &-info {
        width: 785rem;
        min-width: 785rem;
        padding-top: 149rem;

        &__title {
            font-weight: 600;
            font-size: 36rem;
            line-height: 44rem;
            text-align: center;
            color: #4A5F8D;
            margin-bottom: 30rem;

        }

        p {
            max-width: 685rem;
            margin: 0 auto;
            font-weight: 400;
            font-size: 32rem;
            line-height: 39rem;
            color: #EADEDE;
            width: 100%;
            text-align: justify;
        }
    }
}

.we {
    padding-top: 114rem;
    padding-bottom: 120rem;

    h2 {
        margin-bottom: 150rem;
    }

    .ityped-cursor {
        color: transparent;
        border-left: 1.45rem solid #2F80ED;
    }

    &-wrapper {
        display: grid;
        max-width: 1653rem;
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 121rem;
        grid-gap: 115rem;
    }

    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__icon {
            width: 59rem;
            margin-bottom: 38rem;
        }

        h3 {
            font-weight: 700;
            font-size: 36rem;
            line-height: 44rem;
            text-align: center;
            color: #FCFCFC;
            margin-bottom: 22rem;
        }

        p {
            font-weight: 400;
            font-size: 20rem;
            line-height: 29rem;
            text-align: center;
            color: #FCFCFC;
        }
    }

    &-slider {
        .swiper-wrapper {
            height: 100%;
        }

        .swiper-slide {
            height: auto;
        }

        &-item {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: linear-gradient(0deg, rgba(27, 40, 71, 1) 0%, rgba(32, 64, 117, 1) 100%);
            border-radius: 28rem;
            padding: 24rem 28rem 22rem;

            img {
                width: 100%;
                height: 291rem;
                object-fit: cover;
                border-radius: 14rem;
            }

            &__img {
                margin-bottom: 25rem;
            }

            &__title {
                flex: 1;
            }

            h3 {
                font-weight: 500;
                font-size: 26rem;
                line-height: 31rem;
                text-align: center;
                color: #FFFFFF;

                margin-bottom: 61rem;
            }

            &__number {
                font-weight: 400;
                font-size: 20rem;
                line-height: 24rem;
                text-align: center;
                color: #2F80ED;
            }
        }

        &-nav {
            margin-top: 40rem;
            display: flex;
            justify-content: center;

            &__prev {
                margin-right: 23rem;
            }

            &__prev,
            &__next {
                width: 40rem;
                height: 40rem;
                cursor: pointer;
            }

            svg {
                width: 40rem;
                height: 40rem;
            }
        }
    }
}


.how {
    padding-top: 120rem;
    padding-bottom: 120rem;

    .btn--mobile {
        display: none;
    }

    &:after {
        position: absolute;
        content: '';
        width: 2048rem;
        height: 2048rem;
        background: url(../images/common/Ellipse.webp);
        background-size: cover;
        right: -1009rem;
        top: -1052rem;
        user-select: none;
        z-index: -1;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-left {
        max-width: 425rem;
    }

    &__title {
        font-weight: 600;
        font-size: 86rem;
        line-height: 104rem;
        color: #E7EBF2;
        margin-bottom: 58rem;
    }

    &-right {
        display: flex;
    }

    &-items {
        max-width: 995rem;
        margin-right: 87rem;
    }

    &-item {
        background: radial-gradient(100% 100% at 50.05% 0%, #204073 0%, rgba(32, 65, 119, 0) 100%), #1C2B4B;
        border-radius: 28rem;
        padding: 33rem 34rem;
        display: flex;
        margin-bottom: 20rem;
        transition: 0.3s ease-in-out;

        &:last-child {
            margin-bottom: 0;
        }


        &__number {
            width: 29rem;
            min-width: 29rem;
            height: 29rem;
            display: flex;
            align-items: center;
            justify-content: center;
           
            border-radius: 8rem;
            margin-right: 20rem;
            font-weight: 700;
            font-size: 15.7288rem;
            line-height: 19rem;
            color: #FFFFFF;
                background: #5F9FF5;
            transition: 0.3s ease-in-out;
        }

        p {
            font-weight: 500;
            font-size: 24rem;
            line-height: 29rem;
            color: #FCFCFC;
            transition: 0.3s ease-in-out;
        }

        &.active {
            background: radial-gradient(100% 100% at 50.05% 0%, #5B2C3D 0%, rgba(91, 44, 61, 0) 100%), #1C2B4B;

            .how-item__number {
                color: #FFFFFF;
                background: #5F9FF5;
            }

            p {
                color: #FCFCFC;
            }
        }
    }

    &-dots {
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
            background: #2F80ED;
            width: 6rem;
            height: 6rem;
            border-radius: 50%;
            margin-bottom: 30rem;

            &:last-child {
                margin-bottom: 0;
            }

            &.active {
                background: #ffffff;
            }
        }
    }
}

.reviews {
    padding-top: 170rem;
    padding-bottom: 120rem;
    overflow: hidden;

    h2 {
        margin-bottom: 119rem;
    }

    &-wrapper {
        display: flex;
        position: relative;
    }

    &-nav {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;

        &__prev {
            margin-right: 30rem;
            cursor: pointer;

            svg {
                width: 40rem;
                height: 40rem;
            }
        }

        &__next {
            margin-left: 30rem;
            cursor: pointer;

            svg {
                width: 40rem;
                height: 40rem;
            }
        }

    }

    &-pagination {
        font-weight: 400;
        font-size: 40rem;
        line-height: 140%;
        color: #2F80ED;
        position: static;

        .swiper-pagination-current {
            color: #FCFCFC;
        }
    }

    &-pic {
        width: 406rem;
        max-width: 350rem;
        position: relative;

        &::after {
            background: linear-gradient(270deg, #0C1938 0%, rgba(12, 25, 56, 0) 100%);
            width: 423rem;
            height: 818rem;
            position: absolute;
            content: '';
            right: -490rem;
            top: 0;
            z-index: 2;
        }

        .swiper-container {
            min-width: 833rem;
        }

        .swiper-slide {

            transform: scale(0.9);
        }

        .swiper-slide-active {
            transform: scale(1);
        }

        &.reviews-pic--hide {
            .reviews-pic-item__not {
                transform: translateY(130%);
            }
        }

        .swiper-slide-active {
            .reviews-pic-item__not {
                opacity: 1;
            }
        }

        &-item {
            width: 100%;
            overflow: hidden;
            position: relative;

            >img {
                width: 406rem;
            }

            &__not {
                position: absolute;
                left: 22rem;

                background: rgba(30, 30, 30, 0.75);
                backdrop-filter: blur(80px);
                border-radius: 32rem;
                z-index: 2;
                width: 362rem;
                bottom: 22rem;
                padding: 30rem 12rem 17rem;
                transition: transform 0.3s ease-in-out;
                opacity: 0;

                &-title {
                    font-weight: 700;
                    font-size: 26rem;
                    line-height: 28rem;
                    letter-spacing: 0.35rem;
                    color: #FFFFFF;
                    margin-bottom: 27rem;
                    letter-spacing: -0.5rem;
                }

                &-btn {
                    width: 312rem;
                    margin: 0 auto 32rem;
                    height: 46rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #71C67F;
                    border-radius: 24rem;
                    font-weight: 600;
                    font-size: 17rem;
                    line-height: 22rem;
                    letter-spacing: -0.408rem;
                    color: #FFFFFF;
                }

                &-bottom {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                &-img-1 {
                    img {
                        width: 101rem;
                    }
                }

                &-img-2 {
                    img {
                        width: 74rem;
                    }
                }
            }

        }

    }

    &-info {
        max-width: 1112rem;

        .swiper-container {
            height: 100%;
        }

        .swiper-wrapper {
            height: 100%;
        }

        .swiper-slide {
            height: 100%;

            .reviews-info-item {
                opacity: 0;
            }
        }

        .swiper-slide-active {
            .reviews-info-item {
                opacity: 1;
            }

        }

        &-item {
            padding-left: 175rem;
            padding-right: 150rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;


            &__top {
                font-weight: 500;
                font-size: 18rem;
                line-height: 22rem;
                color: #71C67F;
                margin-bottom: 25rem;
                position: relative;
                z-index: 2;
            }

            p {
                font-weight: 400;
                font-size: 32rem;
                line-height: 144.02%;
                color: #FCFCFC;
                position: relative;
                margin-bottom: 28rem;
                z-index: 1;

                &:after {
                    position: absolute;
                    content: '';
                    background: url(../images/common/r1.svg);
                    background-size: cover;
                    width: 159rem;
                    height: 149rem;
                    left: -140rem;
                    top: -160rem;
                    z-index: -1;
                }

                &:before {
                    position: absolute;
                    content: '';
                    background: url(../images/common/r2.svg);
                    background-size: cover;
                    width: 159rem;
                    height: 149rem;
                    right: -20rem;
                    bottom: -140rem;
                    z-index: -1;
                }
            }

            &__date {
                font-weight: 500;
                font-size: 18rem;
                line-height: 22rem;
                color: #71C67F;
                z-index: 1;
            }
        }
    }
}

.contact {
    padding-top: 120rem;
    padding-bottom: 120rem;

    &:after {
        position: absolute;
        content: '';
        width: 1800rem;
        height: 1800rem;
        background: url(../images/common/Ellipse.webp);
        background-size: cover;
        left: -809rem;
        top: -552rem;
        user-select: none;
        z-index: -1;
    }

    h2 {
        margin-bottom: 32rem;
    }

    p {
        max-width: 860rem;
        margin: 0 auto 65rem;
        font-weight: 700;
        font-size: 38rem;
        line-height: 46rem;
        text-align: center;
        color: #C3CDE1;
    }

    form {
        max-width: 808rem;
        margin: 0 auto 29rem;

        .input__placeholder {
            font-weight: 500;
            font-size: 18rem;
            line-height: 150%;
            color: #FCFCFC;

        }

        .field-error {
            .input__placeholder {
                color: #EF382A;
            }
        }

        input {
            height: 50rem;
            padding-left: 0;
            border: none;
            width: 100%;
            font-weight: 400;
            font-size: 22rem;
            line-height: 150%;
            color: #FCFCFC;
            background: transparent;
            border-bottom: 1rem solid #FCFCFC;
            margin-bottom: 23rem;
            padding-bottom: 10rem;

            &::placeholder {
                color: #586179;
            }

        }

        .checkbox {
            display: flex;
            align-items: center;
            margin-bottom: 57rem;
            cursor: pointer;

            input {
                width: 0;
                height: 0;
                visibility: hidden;
                opacity: 0;
                margin: 0;
                padding: 0;

                &:checked~.checkbox__icon {
                    img {
                        opacity: 1;
                    }
                }
            }

            &__icon {
                width: 18rem;
                min-width: 18rem;
                height: 18rem;
                background: #2F80ED;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6rem;
                margin-right: 16rem;

                img {
                    width: 11rem;
                    opacity: 0;
                }
            }

            span {
                font-weight: 500;
                font-size: 20rem;
                line-height: 20rem;
                color: #FCFCFC;
            }
        }

        .btn {
            width: 372rem;
            height: 48rem;
            margin: 0 auto;
            border-radius: 16rem;
        }
    }

    &-social {
        display: flex;
        margin-top: 29rem;
        justify-content: center;

        li {
            margin-right: 27rem;

            &:last-child {
                margin-right: 0;
            }

            img {
                width: 25rem;
            }
        }
    }
}

.thankyou {
    padding-top: 50rem;
    padding-bottom: 179rem;

    .hero-left {
        min-width: 682rem;
        max-width: 682rem;
    }

    .container {
        align-items: center;
    }

    h1 {
        font-size: 70rem;
        line-height: 85rem;
        margin-bottom: 90rem;
    }

    p {
        font-weight: 500;
        font-size: 32rem;
        line-height: 39rem;
    }

    .hero-right img {
        max-width: 909rem;
        width: auto;
        max-height: 90vh;
    }
}

h2 {
    font-weight: 600;
    font-size: 50rem;
    line-height: 61rem;
    color: #FCFCFC;
    margin-bottom: 125rem;
    text-align: center;
}

.btn {
    background: #EF382A;
    border-radius: 16rem;
    width: 281rem;
    height: 71rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 20rem;
    line-height: 20rem;
    color: #FCFCFC;
    border: 0;

    svg {
        margin-left: 8rem;
        width: 11rem;
    }
}

.error404 {
    overflow-x: hidden;
    background: #192C4B;
    min-height: 100vh;

    .wrapper {
        min-height: 100vh;
    }

    main {
        position: relative;
        padding-top: 454rem;
        padding-bottom: 134rem;
        overflow: visible;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:after {
            position: absolute;
            content: '';
            width: 1920rem;
            height: calc(100% + 483rem + 361rem);
            top: -483rem;
            left: 50%;
            transform: translateX(-50%);
            background: url(../images/common/404.jpg);
            background-repeat: no-repeat;
            background-size: 1920rem auto;
            background-position: top center;
            z-index: -1;
        }

        h1 {
            font-weight: 700;
            font-size: 48rem;
            line-height: 20rem;
            color: #FFFFFF;
            margin-bottom: 59rem;
        }
    }
}